import React, { useEffect, useState } from 'react';
import { FaComments } from 'react-icons/fa';
import './chatbot.css';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//code.tidio.co/lxjqzqpcumcivaypilaykp7zwsexhl5q.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup on unmount
    };
  }, []);

  return (
    <div className="chatbot-container">
      {isOpen && (
        <div>
          {/* Tidio chat window will be managed by the script */}
          <button
            onClick={toggleChatbot}
            className="close-button"
          >
            &times; {/* "X" character */}
          </button>
        </div>
      )}
      {!isOpen && (
        <button
          onClick={toggleChatbot}
          className="open-chat-icon"
        >
          <FaComments style={{ fontSize: '30px', color: 'white' }} />
        </button>
      )}
    </div>
  );
};

export default Chatbot;
