// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Chatbot.css */

.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    border-radius: 8px;
   
  }
  
  .chatbot-iframe {
    border: none;
    border-radius: 8px;
    transition: transform 0.2s;
    position: relative; /* Ensure positioning context for the close button */
  }
  
  .close-button {
    position: absolute;
    top: 10px; /* Distance from the top */
    right: 10px; /* Distance from the right */
    background: transparent; /* No background */
    border: none; /* No border */
    color: white; /* Color of the X */
    font-size: 24px; /* Size of the X */
    cursor: pointer;
    z-index: 1; /* Ensure it appears above the iframe */
  }
  
  .open-chat-icon {
    background: blue; /* Background color for the icon */
    border: none; /* No border */
   
    padding: 10px; /* Padding for the icon */
    cursor: pointer; /* Pointer cursor on hover */
    
  }
  `, "",{"version":3,"sources":["webpack://./src/components/chatbot.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;;EAEpB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,kBAAkB,EAAE,oDAAoD;EAC1E;;EAEA;IACE,kBAAkB;IAClB,SAAS,EAAE,0BAA0B;IACrC,WAAW,EAAE,4BAA4B;IACzC,uBAAuB,EAAE,kBAAkB;IAC3C,YAAY,EAAE,cAAc;IAC5B,YAAY,EAAE,mBAAmB;IACjC,eAAe,EAAE,kBAAkB;IACnC,eAAe;IACf,UAAU,EAAE,uCAAuC;EACrD;;EAEA;IACE,gBAAgB,EAAE,kCAAkC;IACpD,YAAY,EAAE,cAAc;;IAE5B,aAAa,EAAE,yBAAyB;IACxC,eAAe,EAAE,4BAA4B;;EAE/C","sourcesContent":["/* src/components/Chatbot.css */\n\n.chatbot-container {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    z-index: 1000;\n    border-radius: 8px;\n   \n  }\n  \n  .chatbot-iframe {\n    border: none;\n    border-radius: 8px;\n    transition: transform 0.2s;\n    position: relative; /* Ensure positioning context for the close button */\n  }\n  \n  .close-button {\n    position: absolute;\n    top: 10px; /* Distance from the top */\n    right: 10px; /* Distance from the right */\n    background: transparent; /* No background */\n    border: none; /* No border */\n    color: white; /* Color of the X */\n    font-size: 24px; /* Size of the X */\n    cursor: pointer;\n    z-index: 1; /* Ensure it appears above the iframe */\n  }\n  \n  .open-chat-icon {\n    background: blue; /* Background color for the icon */\n    border: none; /* No border */\n   \n    padding: 10px; /* Padding for the icon */\n    cursor: pointer; /* Pointer cursor on hover */\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
