import React from "react";
import { Link } from "react-router-dom"; 
import { blog } from "../../../dummydata";
import "./footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' 
    });
};


  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>Have any inquiry?</h1>
            <span>Contact us by sending an email here</span>
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>
      </section>
      <footer>
        <div className='container padding'>
          <div className='box logo'>
            <h1>AI HQ CORP</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
            <p>A company that specializes in integrating artificial intelligence solutions to help other companies improve and remain competitive in their industry.</p>
            <i className='fab fa-facebook-f icon' onClick={() => {
                window.open('https://www.facebook.com/profile.php?id=61565100650606');
              }}></i>
            <i className='fab fa-youtube icon' onClick={() => {
                window.open('https://youtube.com/@aihqcorp?si=3xr8TEsKxVlmot5q ');
              }}></i>
              <i className='fab fa-linkedin icon' onClick={() => {
                window.open('https://www.linkedin.com/in/ai-hq-corp-0b3361328');
              }}></i>
          </div>
          <div className='link'>
            <h3>Explore More Products</h3>
            <ul>
            <li className="webs">
                <Link onClick={() => {window.open('https://art.ginto.ai/', '_blank'); }}>Image Generator</Link>
              </li>
              <li className="webs">
                <Link onClick={() => {window.open('https://ginto.ai', '_blank'); }}>Ginto AI</Link>
              </li>
              <li className="webs">
                <Link onClick={() => {window.open('https://hqcorp.ai', '_blank'); }}>Company Website</Link>
              </li>

            </ul>
          </div>
          <div className='box link'>
            <h3>Quick Links</h3>
            <ul>
            <li><Link to="/about" onClick={scrollToTop}>About Us</Link></li>
              <li><Link to="/courses" onClick={scrollToTop}>Courses</Link></li>
              <li><Link to="/team" onClick={scrollToTop}>Team</Link></li>
              <li><Link to="/courses" onClick={scrollToTop}>Pricing</Link></li>
              <li><Link to="/team" onClick={scrollToTop}>Journal</Link></li>
              <li><Link to="/contact" onClick={scrollToTop}>Contact us</Link></li>
              <li><Link to="/pricing" onClick={scrollToTop}>Pricing</Link></li>
            </ul>
          </div>
         
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                Amaya View Downhill, Sto. Niño, Indahag, Cagayan de Oro city 
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                +63 9945264131
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                aihqdatacenter@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved | AI HQ Corporation
        </p>
      </div>
    </>
  );
}

export default Footer;
