import React from "react"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <h1>AI HQ Corporation</h1>
            <span>ONLINE COURSES & LEARNING</span>
          </div>

          <div className='btn-login'>
          <i className='fab fa-facebook-f icon' onClick={() => {
                window.open('https://www.facebook.com/profile.php?id=61565100650606');
              }}></i>
            <i className='fab fa-youtube icon' onClick={() => {
                window.open('https://youtube.com/@aihqcorp?si=3xr8TEsKxVlmot5q ');
              }}></i>
              <i className='fab fa-linkedin icon' onClick={() => {
                window.open('https://www.linkedin.com/in/ai-hq-corp-0b3361328');
              }}></i>
            
            <button onClick={() => window.location = '/login'} className="btn-login">Login</button>
            <button onClick={() => window.location = '/enrollment'} className="btn-login">Enroll Now</button>
          </div>
          
        </div>
      </section>
    </>
  )
}

export default Head
