import React from "react"
import { team } from "../../dummydata"

const TeamCard = () => {
  return (
    <>
      {team.map((val) => (
        <div className='items shadow'>
          <div className='img'>
            <img src={val.cover} alt='' />
            <div className='overlay'>
            <i className='fab fa-facebook-f icon' onClick={() => {
                window.open('https://www.facebook.com/profile.php?id=61565100650606');
              }}></i>
            
            <i className='fab fa-youtube icon' onClick={() => {
                window.open('https://youtube.com/@aihqcorp?si=3xr8TEsKxVlmot5q ');
              }}></i>
              <i className='fab fa-linkedin icon' onClick={() => {
                window.open('https://www.linkedin.com/in/ai-hq-corp-0b3361328');
              }}></i>
            </div>
          </div>
          <div className='details'>
            <h2>{val.name}</h2>
            <p>{val.work}</p>
          </div>
        </div>
      ))}
    </>
  )
}

export default TeamCard
