import React, { useState } from 'react';
import { FaBook, FaUserTie, FaClock, FaRegStickyNote, FaFileUpload, FaArrowLeft } from 'react-icons/fa'; // Import icons
import './addCourse.css'; 
import { Link } from 'react-router-dom';

const AddCourse = () => {
  const [courseData, setCourseData] = useState({
    industry: '',
    title: '',
    description: '',
    instructor: '',
    duration: '',
    syllabus: null
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseData({
      ...courseData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setCourseData({
      ...courseData,
      syllabus: e.target.files[0] // Set selected file
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!courseData.industry || courseData.industry <= 0) newErrors.industry = 'Industry must be selected';
    if (!courseData.title) newErrors.title = 'Title is required';
    if (!courseData.description) newErrors.description = 'Description is required';
    if (!courseData.instructor) newErrors.instructor = 'Instructor name is required';
    if (!courseData.duration || courseData.duration <= 0) newErrors.duration = 'Duration must be a positive number';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
  
    const formData = new FormData();
    formData.append('industry', courseData.industry);
    formData.append('title', courseData.title);
    formData.append('description', courseData.description);
    formData.append('instructor', courseData.instructor);
    formData.append('duration', courseData.duration);
    if (courseData.syllabus) {
      formData.append('syllabus', courseData.syllabus);
    }
  
    try {
      const response = await fetch('http://192.168.0.186:5000/add-course', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Error adding course');
      }
  
      const result = await response.json();
      alert(result.message);
      // Reset form
      setCourseData({
        industry: '',
        title: '',
        description: '',
        instructor: '',
        duration: '',
        syllabus: null
      });
      setErrors({});
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    
    <div className="add-course-container">
      <Link to="/dashboard" className="back-button" aria-label="Back to Dashboard">
        <FaArrowLeft />
      </Link>
      <h1 className='AddCourseTitle'>Add Course</h1>
      <form className="course-form" onSubmit={handleSubmit}>

      <div className="industry-form-group">
  <label>
    <FaBook className="industry-form-icon" /> Industry:
    <select
    className="industry-select"
    name="industry"
    value={courseData.industry}
    onChange={handleChange}
    required
    aria-label="Industry"
>
    <option value="" disabled>Select an industry</option>
    <option value="AGRICULTURE">Agriculture</option>
    <option value="TECHNOLOGY">Technology</option>
    <option value="INDUSTRIAL">Industrial</option>
    <option value="ARTS AND DESIGN">Arts and Design</option>
    <option value="MUSIC">Music</option>
</select>

    {errors.industry && <span className="industry-error-message">{errors.industry}</span>}
  </label>
</div>

        <div className="form-group">
          <label>
            <FaBook className="form-icon" /> Course Title:
            <input
              type="text"
              name="title"
              value={courseData.title}
              onChange={(e) => handleChange({ target: { name: 'title', value: e.target.value.toUpperCase() } })}
              required
              aria-label="Course Title"
            />
            {errors.title && <span className="error-message">{errors.title}</span>}
          </label>
        </div>
        <div className="form-group">
          <label>
            <FaRegStickyNote className="form-icon" /> Description:
            <textarea
              name="description"
              value={courseData.description}
              onChange={handleChange}
              required
              aria-label="Course Description"
            />
            {errors.description && <span className="error-message">{errors.description}</span>}
          </label>
        </div>
        <div className="form-group">
          <label>
            <FaUserTie className="form-icon" /> Instructor:
            <input
              type="text"
              name="instructor"
              value={courseData.instructor}
              onChange={handleChange}
              required
              aria-label="Instructor Name"
            />
            {errors.instructor && <span className="error-message">{errors.instructor}</span>}
          </label>
        </div>
        <div className="form-group">
          <label>
            <FaClock className="form-icon" /> Duration (hours):
            <input
              type="number"
              name="duration"
              value={courseData.duration}
              onChange={handleChange}
              required
              aria-label="Course Duration"
            />
            {errors.duration && <span className="error-message">{errors.duration}</span>}
          </label>
        </div>
        <div className="form-group">
          <label>
            <FaFileUpload className="form-icon" /> Syllabus:
            <input
              type="file"
              name="syllabus"
              accept=".pdf,.doc,.docx" // Accept specific file types
              onChange={handleFileChange}
              aria-label="Upload Syllabus"
            />
            {courseData.syllabus && <span className="file-name">{courseData.syllabus.name}</span>}
          </label>
        </div>
        <button type="submit" className="submit-button">Add Course</button>
      </form>
      <div className="preview-section">
        <h2>Course Preview</h2>
        <div className="preview-card">
          <h3>{courseData.title || 'Course Title'}</h3>
          <p>{courseData.description || 'Course Description'}</p>
          <p><strong>Instructor:</strong> {courseData.instructor || 'Instructor Name'}</p>
          <p><strong>Duration:</strong> {courseData.duration || '0'} hours</p>
          {courseData.syllabus && <p><strong>Syllabus:</strong> {courseData.syllabus.name}</p>}
        </div>
      </div>
    </div>


  );
};

export default AddCourse;
