import React, { useEffect } from 'react';
import Back2 from "./common/back/Back2";


const EnrollmentForm = () => {
  useEffect(() => {
    const container = document.getElementById('jotform-container');

    if (!document.getElementById('jotform-script')) {
      const script = document.createElement('script');
      script.id = 'jotform-script'; 
      script.src = "https://form.jotform.com/jsform/242830550559459";
      script.async = true; // Ensures the script loads asynchronously
      container.appendChild(script);
    }
  }, []);

  

  return (
    <div>
     
      <div id="jotform-container"></div>
      <Back2 />
    </div>
  );
};

export default EnrollmentForm;
