import React from 'react';
import Headerdash from '../Headerdash';  
import '../Dashboard.css';    
import Addcourse from './addcourse';


const AddCourseHome = () => {
  return (
    <>
    <Headerdash/>
    <div className="main-container"> 
    <Addcourse/>
    </div>
    </>
  );
}

export default AddCourseHome;
