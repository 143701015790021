import React from "react"
import { awrapper } from "../../dummydata"
import "./wrapper.css"

const Awrapper = () => {
  return (
    <section className='about-awrapper'>
      <div className='about-container'>
        {awrapper.map((val) => {
          return (
            <div className='about-box'>
            
              <div className='about-text'>
                <h1>{val.data}</h1>
                <h3>{val.title}</h3>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Awrapper
