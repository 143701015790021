import React, { useState } from 'react';
import Headerdash from '../Headerdash';  
import '../Dashboard.css';    
import Addusers from './addusers';
import Viewusers from './viewusers';

const AddUsersHome = () => {
  const [showAddUsers, setShowAddUsers] = useState(true); 

  const toggleView = () => {
    setShowAddUsers(!showAddUsers); 
  };

  return (
    <>
      <Headerdash />
      <div className="main-container">
        <button className="viewusers" onClick={toggleView}>
          {showAddUsers ? 'View Users' : 'Add Users'}
        </button>
        
        {showAddUsers ? <Addusers /> : <Viewusers />}
      </div>
    </>
  );
}

export default AddUsersHome;
