import React from 'react';
import Headerdash from './Headerdash';  
import SideBar from './SideBar'; 
import './Dashboard.css';   
import CenterSpace from './CenterSpace';      

const HomePrograms = () => {
  return (
    <>
      <Headerdash />
      <div className="main-container">
        <SideBar className="sidebar" />
        <CenterSpace/>


        
       
      </div>
    </>
  );
}

export default HomePrograms;
