// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/bg2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}

.primay-btn{
  z-index: 100 !important;
}

.primay-btn:hover{
  z-index: 100 !important; 
  cursor: pointer;
}

.button{
  z-index: 100 !important;
}

button{
  z-index: 100 !important;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
}



`, "",{"version":3,"sources":["webpack://./src/components/home/hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,yDAA0D;EAC1D,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,gBAAgB;IAChB,YAAY;EACd;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".hero {\n  background-image: url(\"../../../../public/images/bg2.jpg\");\n  background-size: cover;\n  background-attachment: fixed;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n  width: 100%;\n  height: 100vh;\n  padding-top: 20%;\n  padding-right: 50px;\n  color: #fff;\n}\n\n.primay-btn{\n  z-index: 100 !important;\n}\n\n.primay-btn:hover{\n  z-index: 100 !important; \n  cursor: pointer;\n}\n\n.button{\n  z-index: 100 !important;\n}\n\nbutton{\n  z-index: 100 !important;\n}\n.hero #heading h3 {\n  color: #fff;\n}\n.hero #heading {\n  text-align: left;\n  margin: 0;\n  padding: 0;\n}\n@media screen and (max-width: 768px) {\n  .margin {\n    height: 40vh;\n  }\n  .hero {\n    background-position: right;\n    padding-top: 50%;\n    height: 80vh;\n  }\n  .hero .row {\n    width: 100%;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
