// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog img {
  width: 100%;
  height: 20rem;
}
.blog .text {
  padding: 40px 30px;
}
.blog h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.blog h1:hover {
  color: #1eb2a6;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #1eb2a6;
}
.blog span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog span:nth-last-child(1) label {
  color: #1eb2a6;
}
.blog p {
  color: grey;
}

/* @media only screen and (max-width: 1380px) {
  .container{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
  }
} */`, "",{"version":3,"sources":["webpack://./src/components/blog/blog.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;AACb;;AAEA;;;;;GAKG","sourcesContent":[".blog img {\n  width: 100%;\n  height: 20rem;\n}\n.blog .text {\n  padding: 40px 30px;\n}\n.blog h1 {\n  font-weight: 500;\n  font-size: 22px;\n  line-height: 30px;\n  margin: 20px 0;\n  transition: 0.5s;\n}\n.blog h1:hover {\n  color: #1eb2a6;\n  cursor: pointer;\n}\n.blog span {\n  text-transform: uppercase;\n  font-weight: 500;\n  color: #1eb2a6;\n}\n.blog span label {\n  margin-left: 10px;\n  color: grey;\n  font-size: 14px;\n}\n.blog span:nth-last-child(1) label {\n  color: #1eb2a6;\n}\n.blog p {\n  color: grey;\n}\n\n/* @media only screen and (max-width: 1380px) {\n  .container{\n      display: grid;\n      grid-template-columns: repeat(2, 1fr);\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
