// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team .items {
  transition: 0.5s;
}
.team .img {
  position: relative;
}
.team img {
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  padding: 20px;
  opacity: 0;
  transition: 0.5s;
}
.overlay i {
  background-color: #000;
  color: #fff;
  margin: 5px;
  transition: 0.5s;
}
.team .details {
  padding: 30px;
  text-align: center;
}
.team .details h2 {
  font-weight: 500;
  font-size: 20px;
  transition: 0.5s;
}
.team .details p {
  font-size: 15px;
  color: grey;
  margin-top: 10px;
  transition: 0.5s;
}
.team .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.team .items:hover p {
  color: #fff;
}
.team .items:hover .overlay {
  opacity: 1;
}
.team .items:hover .overlay i:hover {
  background-color: #1eb2a6;
  color: #fff;
}
.team img {
  width: 100%;
  height: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/components/team/team.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":[".team .items {\n  transition: 0.5s;\n}\n.team .img {\n  position: relative;\n}\n.team img {\n  width: 100%;\n  height: 100%;\n}\n.overlay {\n  position: absolute;\n  bottom: 0;\n  z-index: 11;\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  opacity: 0;\n  transition: 0.5s;\n}\n.overlay i {\n  background-color: #000;\n  color: #fff;\n  margin: 5px;\n  transition: 0.5s;\n}\n.team .details {\n  padding: 30px;\n  text-align: center;\n}\n.team .details h2 {\n  font-weight: 500;\n  font-size: 20px;\n  transition: 0.5s;\n}\n.team .details p {\n  font-size: 15px;\n  color: grey;\n  margin-top: 10px;\n  transition: 0.5s;\n}\n.team .items:hover {\n  background-color: #1eb2a6;\n  color: #fff;\n  cursor: pointer;\n}\n.team .items:hover p {\n  color: #fff;\n}\n.team .items:hover .overlay {\n  opacity: 1;\n}\n.team .items:hover .overlay i:hover {\n  background-color: #1eb2a6;\n  color: #fff;\n}\n.team img {\n  width: 100%;\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
