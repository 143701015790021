import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  function handleGetStarted() {
    console.log('GET STARTED NOW clicked')
  }

  function handleViewCourse() {
    console.log('VIEW COURSE clicked')
  }

  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading title='Best Online Education Platform' />
            <p>ARTIFICIAL INTELLIGENCE CORPORATION</p>
            
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
