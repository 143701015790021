// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contacts .right {
  padding: 50px;
}
.contacts h1 {
  font-size: 30px;
  font-weight: 400;
}
.contacts p {
  color: grey;
}
.contacts .items {
  margin: 30px 0;
}
.contacts .box p {
  font-size: 17px;
  margin-top: 10px;
}
.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
}
form .flexSB input:nth-child(1) {
  margin-right: 10px;
}
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contacts span {
  color: #1eb2a6;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .contacts .container {
    flex-direction: column;
  }
  .contacts iframe {
    height: 50vh;
  }
  .contacts .row {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/contact/contact.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;;EAEE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;EACd;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".contacts iframe {\n  width: 100%;\n  height: 100%;\n  border: none;\n}\n.contacts .right {\n  padding: 50px;\n}\n.contacts h1 {\n  font-size: 30px;\n  font-weight: 400;\n}\n.contacts p {\n  color: grey;\n}\n.contacts .items {\n  margin: 30px 0;\n}\n.contacts .box p {\n  font-size: 17px;\n  margin-top: 10px;\n}\n.contacts textarea,\n.contacts input {\n  width: 100%;\n  padding: 15px;\n  margin-bottom: 15px;\n  outline: none;\n  border: 1px solid grey;\n}\nform .flexSB input:nth-child(1) {\n  margin-right: 10px;\n}\n.contacts h3 {\n  margin-top: 20px;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n.contacts span {\n  color: #1eb2a6;\n  font-weight: 600;\n  font-size: 15px;\n  word-spacing: 5px;\n}\n@media screen and (max-width: 768px) {\n  .contacts .container {\n    flex-direction: column;\n  }\n  .contacts iframe {\n    height: 50vh;\n  }\n  .contacts .row {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
