import React from 'react';
import Headerdash from '../Headerdash';  
import '../Dashboard.css';    
import Profile1 from './profile';

const ProfilePrograms = () => {
  return (
    <>
    <Headerdash/>
    <div className="main-container"> 
    <Profile1/>
    </div>
    </>
  );
}

export default ProfilePrograms;
