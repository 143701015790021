import React, { useState } from 'react';
import './Profile.css';
import logo from "../ai.png"

const Profile = () => {
const [photo, setPhoto] = useState(logo);

const handlePhotoChange = (event) => {
const file = event.target.files[0];
if (file) {
const reader = new FileReader();
reader.onloadend = () => {
setPhoto(reader.result);
};
reader.readAsDataURL(file);
}
};

const handleButtonClick = () => {
document.getElementById('photo-input').click();
};

return (
<div class="profile-settings-wrapper">
<div className="profile-settings-container">
<div className="profile-settings-header">
<img
src={photo}
alt="Profile"
className="profile-settings-photo"
/>
<div className="photo-change-section">
<input
id="photo-input"
type="file"
accept="image/*"
className="profile-settings-file-input"
onChange={handlePhotoChange}
style={{ display: 'none' }}
/>
<button onClick={handleButtonClick} className="photo-change-button">
Change Photo
</button>
</div>
<h1 className="user-name">John Doe</h1>
</div>
<div className="profile-settings-details">
<h2 className='Pdetails'>DETAILS</h2>
<div className="detail-item">
<div className="detail-label">First Name:</div>
<div className="detail-value">John</div>
</div>
<div className="detail-item">
<div className="detail-label">Last Name:</div>
<div className="detail-value">Doe</div>
</div>
<div className="detail-item">
<div className="detail-label">Birthdate:</div>
<div className="detail-value">January 1, 1990</div>
</div>
<div className="detail-item">
<div className="detail-label">Age:</div>
<div className="detail-value">34</div>
</div>
<div className="detail-item">
<div className="detail-label">Email:</div>
<div className="detail-value">john.doe@example.com</div>
</div>
</div>

</div>
</div>
);
}

export default Profile;
