// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price .items {
  padding: 40px;
  text-align: center;
}
.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #1eb2a6;
}
.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.price p {
  color: grey;
  margin: 40px 0;
}
/*--------------faq-------------*/
.faq .container {
  max-width: 70%;
  border-radius: 5px;
}
.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #1eb2a6;
  color: #fff;
}

.currency {
  font-size: 20px;
  margin-right: 5px;
  color: #1eb2a6;
}

/*--------------faq-------------*/
`, "",{"version":3,"sources":["webpack://./src/components/pricing/price.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,cAAc;EACd,cAAc;AAChB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,WAAW;EACX,cAAc;AAChB;AACA,iCAAiC;AACjC;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA,iCAAiC","sourcesContent":[".price .items {\n  padding: 40px;\n  text-align: center;\n}\n.price h1 {\n  font-size: 50px;\n  margin: 20px 0;\n  color: #1eb2a6;\n}\n.price h1 span {\n  font-size: 20px;\n  margin-right: 5px;\n  color: #000;\n}\n.price p {\n  color: grey;\n  margin: 40px 0;\n}\n/*--------------faq-------------*/\n.faq .container {\n  max-width: 70%;\n  border-radius: 5px;\n}\n.faq .box {\n  background-color: #fff;\n  margin-bottom: 20px;\n}\n.faq button {\n  padding: 0;\n  margin: 0;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: 15px 20px;\n}\n.faq h2 {\n  font-weight: 500;\n}\n.faq p {\n  padding: 20px;\n}\n.faq .accordion:hover {\n  background-color: #1eb2a6;\n  color: #fff;\n}\n\n.currency {\n  font-size: 20px;\n  margin-right: 5px;\n  color: #1eb2a6;\n}\n\n/*--------------faq-------------*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
