// LoginCard.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const LoginCard = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://192.168.0.186:5000/login', { username, password });
      alert(response.data.message); 
     
      navigate('/dashboard');
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'An error occurred while logging in.';
      setError(errorMessage);
      console.error('Login Error:', err);
    }
  };

  return (
    <div className="login-container">
      <section className="login-card">
        <h1 className='LoginTitle'>LOGIN</h1>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="input-group">
            <div className="input-group-item">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                placeholder="john123"
                required
                className="input-field"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input-group-item">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="•••••••••"
                required
                className="input-field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      </section>
    </div>
  );
};

export default LoginCard;
