import React, { useState } from 'react';
import './addC.css'; // Ensure this file has the updated styles

const AddContent = () => {
  const [contentType, setContentType] = useState('assignment');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [file, setFile] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const contentData = {
      contentType,
      title,
      description,
      dueDate,
      file,
    };
    console.log('Content submitted:', contentData);
    // Reset form fields
    setTitle('');
    setDescription('');
    setDueDate('');
    setFile(null);
  };

  return (
    <div className="add-content-container">
      <div className="add-content-sidebar">
        <h3>Select Content Type</h3>
        <ul>
          <li onClick={() => setContentType('assignment')} className={contentType === 'assignment' ? 'add-content-active' : ''}>Assignment</li>
          <li onClick={() => setContentType('quiz')} className={contentType === 'quiz' ? 'add-content-active' : ''}>Quiz</li>
          <li onClick={() => setContentType('exercise')} className={contentType === 'exercise' ? 'add-content-active' : ''}>Exercise</li>
        </ul>
      </div>
      <div className="add-content-form">
        <h2>Add {contentType.charAt(0).toUpperCase() + contentType.slice(1)}</h2>
        <form onSubmit={handleSubmit}>
          <div className="add-content-title-input">
            <label>
              Title:
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="add-content-description-input">
            <label>
              Description:
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                className={contentType === 'quiz' ? 'add-content-quiz-description' : ''}
              />
            </label>
          </div>

          <div className="add-content-due-date">
            <label>
              Due Date:
              <input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </label>
          </div>

          <div className="add-content-file-upload">
            <label>
              Upload File:
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </label>
          </div>

          <button type="submit" className="add-content-submit-button">
            Add {contentType.charAt(0).toUpperCase() + contentType.slice(1)}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddContent;
