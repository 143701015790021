import React, { useRef } from "react";
import Back from "../common/back/Back";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'; // Import SweetAlert2
import "./contact.css";

const Contact = () => {
  const form = useRef();
  const map =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23768.746411870456!2d124.6543155!3d8.4229416!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32ff8da4b96dbe4b:0x292a68ffb3dd2011!2sAmaya%20View!5e0!3m2!1sen!2sph!4v1693890882380!5m2!1sen!2sph";

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_f64mrc9', 'template_eovurn6', form.current, {
        publicKey: 'YtMlt1NvjrwXbLmv3',
      })
      .then(
        () => {
          Swal.fire({
            title: 'Success!',
            text: 'Your message has been sent successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
          form.current.reset(); // Optionally reset the form after successful submission
        },
        (error) => {
          Swal.fire({
            title: 'Failed!',
            text: `There was an error sending your message: ${error.text}`,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
  };

  return (
    <>
      <Back title="Contact us" />
      <section className="contacts padding">
        <div className="container shadow flexSB">
          <div className="left row">
            <iframe src={map} title="Map"></iframe>
          </div>
          <div className="right row">
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>Amaya Drive Downhill, Sto. Niño, Indahag, Cagayan de Oro City, Misamis Oriental</p>
              </div>
              <div className="box">
                <h4>EMAIL:</h4>
                <p>aihqdatacenter@gmail.com</p>
              </div>
              <div className="box">
                <h4>PHONE:</h4>
                <p> +639 692 123 616</p>
              </div>
            </div>

            <form ref={form} onSubmit={sendEmail}>
              <div className="flexSB">
                <input type="text" name="to_name" placeholder="Name" required />
                <input type="email" name="from_email" placeholder="Email" required />
              </div>
              <input type="text" name="from_name" placeholder="Subject" required />
              <textarea name="message" cols="30" rows="10" placeholder="Create a message here..." required></textarea>
              <button type="submit" className="primary-btn">SEND MESSAGE</button>
            </form>

    
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
