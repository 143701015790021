// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 90px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/testimonal/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;AACA;;EAEE,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".testimonal .items {\n  padding: 30px;\n  transition: 0.5s;\n}\n\n.testimonal .img {\n  position: relative;\n}\n.testimonal img {\n  width: 90px;\n  height: 100px;\n  border-radius: 50%;\n}\n.testimonal i {\n  position: absolute;\n  bottom: 0;\n  left: 60px;\n  background-color: #1eb2a6;\n  color: #fff;\n}\n.testimonal .name {\n  margin: 25px;\n}\n.testimonal .name h2 {\n  font-size: 20px;\n  margin-bottom: 5px;\n}\n.testimonal .name span {\n  color: #1eb2a6;\n}\n.testimonal p {\n  margin-top: 20px;\n  color: grey;\n  transition: 0.5s;\n}\n.testimonal .items:hover {\n  background-color: #1eb2a6;\n  color: #fff;\n  cursor: pointer;\n}\n.testimonal .items:hover span,\n.testimonal .items:hover p {\n  color: #fff;\n}\n.testimonal .items:hover i {\n  background-color: #fff;\n  color: #1eb2a6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
