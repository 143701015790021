import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsGrid1X2Fill, BsChevronUp, BsChevronDown } from 'react-icons/bs';
import './Dashboard.css';
import AddContent from './AddingContent/AddContent';

function Sidebar({ openSidebarToggle }) {
  const [courses, setCourses] = useState([]);
  const [content, setContent] = useState(null);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [showCreateContentButton, setShowCreateContentButton] = useState(false);
  const [showCreateContent, setShowCreateContent] = useState(false);

  const fetchCourses = async () => {
    console.log("Fetching courses...");
    try {
      const response = await fetch('http://192.168.0.186:5000/get-courses');
      console.log("Response received:", response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Data fetched:", data);
      setCourses(data.courses);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered");
    fetchCourses();
  }, []);

  const toggleDropdown = (industry) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [industry]: !prev[industry]
    }));
  };

  const fetchCourseInfo = (course) => {
    setContent(
      <div>
        <h2>{course.title}</h2>
        <p><strong>Description:</strong> {course.description}</p>
        <p><strong>Instructor:</strong> {course.instructor}</p>
        <p><strong>Duration:</strong> {course.duration} hours</p>
        {course.syllabus && <p><strong>Syllabus:</strong> <a href={course.syllabus} target="_blank" rel="noopener noreferrer">Download</a></p>}
      </div>
    );
    setShowCreateContentButton(true); // Show the button when a course is selected
  };

  const handleButtonClick = () => {
    setShowCreateContent(!showCreateContent);
  };

  const coursesByIndustry = courses.reduce((acc, course) => {
    acc[course.industry] = acc[course.industry] || [];
    acc[course.industry].push(course);
    return acc;
  }, {});

  return (
    <div className="main-container">
      <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
        <div className='sidebar-title'>
          <div className='sidebar-brand'>
            <BsGrid1X2Fill className='sidebar-icon icon-color' /> DASHBOARD
          </div>
        </div>

        <ul className='sidebar-list'>
          {Object.keys(coursesByIndustry).map(industry => (
            <li key={industry} className={`sidebar-list-item ${openDropdowns[industry] ? 'open' : ''}`}>
              <Link to="#" onClick={() => toggleDropdown(industry)} className="sidebar-link">
                {industry}
                <span className={`dropdown-icon ${openDropdowns[industry] ? 'open' : ''}`}>
                  {openDropdowns[industry] ? <BsChevronUp /> : <BsChevronDown />}
                </span>
              </Link>
              {openDropdowns[industry] && (
                <ul className='dropdown-menu'>
                  {coursesByIndustry[industry].map(course => (
                    <li key={course.id}>
                      <a href="#" onClick={() => fetchCourseInfo(course)}>
                        {course.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </aside>

      <div className='space'>
        {showCreateContent ? (
          <AddContent /> 
        ) : content}

        {showCreateContentButton && (
          <button className="create-content-button" onClick={handleButtonClick}>
            {showCreateContent ? 'Back to Courses' : 'Create a Content here'}
          </button>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
