import React, { useState } from 'react';
import axios from 'axios';
import './Addusers.css';

const Register = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    birthdate: '',
    gender: '',
    age: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    role: '' 
  });
  
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track submission

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post('http://192.168.0.186:5000/register', formData);
      alert(response.data.message);
      setIsSubmitted(true); // Set submitted to true on successful registration
    } catch (error) {
      alert(error.response?.data?.error || 'An error occurred while registering.');
      console.error('Error:', error);
    }
  };

  const handleAddUser = () => {
    setFormData({ // Reset form data
      firstname: '',
      lastname: '',
      birthdate: '',
      gender: '',
      age: '',
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      role: '' 
    });
    setIsSubmitted(false); 
  };

  return (
    <div className="reg-settings-wrapper">
      <div className="register-container">
        <section className="register-card wide">
          <h1>Enroll a Student</h1>
          <form onSubmit={handleSubmit} className="form-container wide">
            {/* Existing form fields go here */}
            <div className="input-group input-group-row">
              <div className="input-group-item">
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  id="firstname"
                  placeholder="John"
                  required
                  className="input-field"
                  value={formData.firstname}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group-item">
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  id="lastname"
                  placeholder="Doe"
                  required
                  className="input-field"
                  value={formData.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="input-group input-group-row">
              <div className="input-group-item">
                <label htmlFor="birthdate">Birth Date</label>
                <input
                  id="birthdate"
                  className="input-field"
                  type="date"
                  required
                  value={formData.birthdate}
                  onChange={handleChange}
                />
              </div>

              <div className="input-group-item">
                <label htmlFor="gender">Gender</label>
                <select
                  id="gender"
                  required
                  className="input-field"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select your gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div className="input-group input-group-row">
              <div className="input-group-item">
                <label htmlFor="age">Age</label>
                <input
                  type="number"
                  id="age"
                  placeholder="18"
                  required
                  className="input-field"
                  value={formData.age}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group-item">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="johndoe@example.com"
                  required
                  className="input-field"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="input-group input-group-row">
              <div className="input-group-item">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  placeholder="john123"
                  required
                  className="input-field"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group-item">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="•••••••••"
                  required
                  className="input-field"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group-item">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="•••••••••"
                  required
                  className="input-field"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Role Selection Dropdown */}
            <div className="input-group input-group-row">
              <div className="input-group-item">
                <label htmlFor="role">Role</label>
                <select
                  id="role"
                  required
                  className="input-field"
                  value={formData.role}
                  onChange={handleChange}
                >
                  <option value="">Select your role</option>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="teacher">Teacher</option>
                </select>
              </div>
            </div>

            <button type="submit" className="login-btn wide">
              Register
            </button>
          </form>

          {/* Add User Button displayed after successful submission */}
          {isSubmitted && (
            <button onClick={handleAddUser} className="add-user-btn wide">
              Add Another User
            </button>
          )}
        </section>
      </div>
    </div>
  );
};

export default Register;
