import "./App.css";
import Chatbot from './components/Chatbot';
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import About from "./components/about/About";
import CourseHome from "./components/allcourses/CourseHome";
import Team from "./components/team/Team";
import Pricing from "./components/pricing/Pricing";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";
import Login from "./components/Login/LoginHome";
import HomePrograms from "./components/Dashboard/HomePrograms";
import Profile from './components/Dashboard/ProfileMenu/ProfilePrograms';
import AddUsersHome from "./components/Dashboard/AddUsers/AddUsersHome";
import AddCourseHome from "./components/Dashboard/AddCourse/ACHome";
import EnrollmentForm from "./components/enrollmentform";


// Layout component that conditionally includes Header and Footer
const MainLayout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
    <Chatbot />

  </>
);

// Layout component for Dashboard that does not include Header and Footer
const DashboardLayout = () => (
  <Outlet />
);

function App() {
  return (
    <Router>
      <Routes>
        {/* Main application routes with Header and Footer */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<CourseHome />} />
          <Route path="/team" element={<Team />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/journal" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/enrollment" element={<EnrollmentForm />} />
        </Route>

        {/* Dashboard routes without Header and Footer */}
        <Route element={<DashboardLayout />}>
          <Route path="/dashboard" element={<HomePrograms />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/addusers" element={<AddUsersHome />} />
          <Route path="/addcourse" element={<AddCourseHome />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
