import React from 'react'
import Back2 from "../common/back/Back2"
import LoginCard from "./LoginCard"

const LoginHome = () => {
  return (
    
    <>
    <LoginCard/>
    <Back2/>
    
    
      
    </>
  )
}

export default LoginHome
