import React, { useState } from 'react';
import { BsFillBellFill, BsPersonCircle } from 'react-icons/bs';
import { FaSearch, FaUser, FaCog, FaSignOutAlt, FaCreditCard, FaUserPlus, FaUserFriends } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'; 
import './Dashboard.css';
import logo from "../Dashboard/ai.png";
import AddCourse from './AddCourse/ACHome';

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Initial state: user is logged in
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Set login state to false
    navigate('/login'); // Navigate to the login page
    window.history.pushState(null, null, window.location.href); // Prevent back navigation
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href); // Keep the user on the login page
    };
  };

  return (
    <header className='header'>
      <div className='header-left'>
        <img src={logo} alt="Logo" className="logo-image" />
        <div className='button-container'>
        <Link to="/addcourse">
          <button className='drop-down'>Add Course</button>
          </Link>
          <button className='drop-down'>Tutorials</button>
          <button className='drop-down'>Exam</button>
          <button className='drop-down'>Certificates</button>
        </div>
        <input type="text" placeholder="Search..." className="search-bar" />
        <FaSearch className='search-icon' />
      </div>

      <div className='header-right'>
        <BsFillBellFill className='icon profile-icon' />
        <div className='profile-container'>
          <div onClick={toggleDropdown} className='profile-icon-container'>
            <BsPersonCircle className='icon profile-icon' />
          </div>
          {isDropdownOpen && (
            <ul className='dropdown-menuP'>
              <li>
                <Link to="/profile">
                  <FaUser className='dropdown-iconP' />
                  View Profile
                </Link>
              </li>
              <li>
                <Link to="/addusers">
                  <FaUserPlus className='dropdown-iconP' />
                  Add Users
                </Link>
              </li>
              <li>
                <Link to="/addusers">
                  <FaUserFriends className='dropdown-iconP' />
                  View Users
                </Link>
              </li>
              <li>
                <Link to="/payment">
                  <FaCreditCard className='dropdown-iconP' />
                  Payment
                </Link>
              </li>
              <li>
                <Link to="/settings">
                  <FaCog className='dropdown-iconP' />
                  Settings
                </Link>
              </li>
              <li onClick={handleLogout}>
                <span className='dropdown-iconP'>
                  <FaSignOutAlt />
                </span>
                Logout
              </li>
            </ul>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
