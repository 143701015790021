export const homeAbout = [
    {
      id: 1,
      cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
      title: "Online Courses",
      desc: "Can be accessed from any location with an internet connection, providing learners with greater flexibility in where they learn.",
    },
    {
      id: 1,
      cover: "https://img.icons8.com/ios/80/000000/diploma.png",
      title: "Earn A Certificates",
      desc: "Serve as proof that an individual has completed a specific course or program and demonstrates their competence in the subject matter.",
    },
    {
      id: 1,
      cover: "https://img.icons8.com/ios/80/000000/athlete.png",
      title: "Learn with Expert",
      desc: "Provides learners with access to experienced professionals who can guide, and support them throughout their studies, enhancing the overall learning experience.",
    },
  ]
  export const awrapper = [
    {
      cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
      data: "50",
      title: "STUDENTS",
    },
  
    {
      cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
      data: "20",
      title: "TRUSTED TUTORS",
    },
    {
      cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
      data: "250",
      title: "SCHEDULES",
    },
    {
      cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
      data: "50",
      title: "COURSES",
    },
  ]
  export const coursesCard = [
    {
      id: 1,
      category:"programming",
      cover: "../images/courses/c1.png",
      coursesName: "Computer Literacy",
      courTeacher: [
        {
          dcover: "./images/jireh.jpg",
          name: "by Jireh Magellanes",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 2,
      cover: "../images/courses/c2.png",
      category:"programming",
      coursesName: "Web Development",
      courTeacher: [
        {
          dcover: "./images/jerome.jpg",
          name: "by Jerome Porcado",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 3,
      cover: "../images/courses/c3.png",
      category:"programming",
      coursesName: "Graphic Designing",
      courTeacher: [
        {
          dcover: "./images/HtozvSZy.jpeg",
          name: "by Kim Sagala",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 4,
      cover: "../images/courses/c4.png",
      category:"programming",
      coursesName: "Programming",
      courTeacher: [
        {
          dcover: "./images/jireh.jpg",
          name: "by Jireh Magellanes",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 5,
      cover: "../images/courses/c5.png",
      category:"programming",
      coursesName: "Hydroponics",
      courTeacher: [
        {
          dcover: "./images/jenny.jpeg",
          name: "by Jenny Batal",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 6,
      cover: "../images/courses/c6.png",
      category:"development",
      coursesName: "Aeroponics",
      courTeacher: [
        {
          dcover: "./images/jireh.jpg",
          name: "by Jireh Magellanes",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 7,
      cover: "../images/courses/c7.png",
      category:"development",
      coursesName: "Aquaponics",
      courTeacher: [
        {
          dcover: "./images/jireh.jpg",
          name: "by Jireh Magellanes",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 8,
      cover: "../images/courses/c8.png",
      category:"development",
      coursesName: "Painting",
      courTeacher: [
        {
          dcover: "./images/jireh.jpg",
          name: "by Jireh Magellanes",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
    {
      id: 9,
      cover: "../images/courses/c9.png",
      category:"development",
      coursesName: "Electronics",
      courTeacher: [
        {
          dcover: "./images/jireh.jpg",
          name: "by Jireh Magellanes",
          totalTime: "Hands-on Exercises",
        },
      ],
      priceAll: "Explore",
      pricePer: "₱5,000 per month",
    },
  ]
  export const online = [
    {
      cover: "./images/courses/online/o1.png",
      hoverCover: "./images/courses/online/o1.1.png",
      courseName: "UI/UX Design Courses",
      course: "25 Courses",
    },
    {
      cover: "./images/courses/online/o12.png",
      hoverCover: "./images/courses/online/o12.1.png",
      courseName: "Web Management",
      course: "17 Courses",
    },
    {
      cover: "./images/courses/online/o3.png",
      hoverCover: "./images/courses/online/o3.1.png",
      courseName: "Computer Science",
      course: "10 Courses",
    },
    {
      cover: "./images/courses/online/o4.png",
      hoverCover: "./images/courses/online/o4.1.png",
      courseName: "History & Archeologic",
      course: "15 Courses",
    },
    {
      cover: "./images/courses/online/o5.png",
      hoverCover: "./images/courses/online/o5.1.png",
      courseName: "Software Engineering",
      course: "30 Courses",
    },
    {
      cover: "./images/courses/online/o6.png",
      hoverCover: "./images/courses/online/o6.1.png",
      courseName: "Information Software",
      course: "60 Courses",
    },
    {
      cover: "./images/courses/online/o7.png",
      hoverCover: "./images/courses/online/o7.1.png",
      courseName: "Health",
      course: "10 Courses",
    },
    {
      cover: "./images/courses/online/o8.png",
      hoverCover: "./images/courses/online/o8.1.png",
      courseName: "Marketing",
      course: "30 Courses",
    },
    {
      cover: "./images/courses/online/o9.png",
      hoverCover: "./images/courses/online/o9.1.png",
      courseName: "Graphic Design",
      course: "80 Courses",
    },
    {
      cover: "./images/courses/online/o10.png",
      hoverCover: "./images/courses/online/o10.1.png",
      courseName: "Music",
      course: "120 Courses",
    },
    {
      cover: "./images/courses/online/o11.png",
      hoverCover: "./images/courses/online/o11.1.png",
      courseName: "System Dev",
      course: "17 Courses",
    },
    {
      cover: "./images/courses/online/o2.png",
      hoverCover: "./images/courses/online/o2.1.png",
      courseName: "Art & Design",
      course: "25 Courses",
    },
   
  ]
  export const team = [
    {
      cover: "./images/jerome.jpg",
      name: "Jerome B. Porcado",
      work: "Full-Stack Developer",
    },
    {
      cover: "./images/jireh.jpg",
      name: "Jireh Magellanes",
      work: "Full-Stack Developer",
    },
    {
      cover: "./images/HtozvSZy.jpeg",
      name: "Kim John P. Sagala",
      work: "Full-Stack Developer",
    },
    {
      cover: "./images/jenny.jpeg",
      name: "Jenny Batal",
      work: "Full-Stack Developer",
    },
    {
      cover: "./images/reinan.jpeg",
      name: "Reinan Sabas",
      work: "Marketing Strategist",
    },
    {
      cover: "./images/precy.jpg",
      name: "Precy Lyn Lagumen",
      work: "Curriculum Developer",
    },
    

  ]
  export const price = [
    {
      name: "K-12",
      price: "5,000",
      desc: "Explore our wide range of courses in Artificial Intelligence, designed to help you master the skills needed in the AI industry.",
    },
    {
      name: "UNDERGRAD",
      price: "5,000",
      desc: "Explore our wide range of courses in Artificial Intelligence, designed to help you master the skills needed in the AI industry.",
    },
    {
      name: "POST GRAD",
      price: "5,000",
      desc: "Explore our wide range of courses in Artificial Intelligence, designed to help you master the skills needed in the AI industry.",
    },
    {
      name: "MASTER CLASS",
      price: "5,000",
      desc: "Explore our wide range of courses in Artificial Intelligence, designed to help you master the skills needed in the AI industry.",
    },
  ]
  export const faq = [
    {
      title: "How to Enroll?",
      desc: "To enroll, you first need to register. After that, please call us or visit us to complete your payment and discuss any negotiations.",
    },
    {
      title: "What are the payment options?",
      desc: "We accept various payment methods, including cash, gcash, paypal, credit/debit cards, bank transfers, and online payment platforms. Please contact us for more details.",
    },
    {
      title: "How long does the enrollment process take?",
      desc: "The enrollment process typically takes one to three business days, depending on the completion of registration and payment.",
    },
    {
      title: "Can I access my account from multiple devices?",
      desc: "Yes, you can access your account from multiple devices, ensuring you can stay connected wherever you are.",
    },
    {
      title: "Are there any user limits on the account?",
      desc: "User limits depend on your chosen plan. Please review the plan details or contact us for specific information regarding user limits.",
    },
    {
      title: "Do you offer referral bonuses?",
      desc: "Yes, we have a referral program that rewards you for bringing in new customers. Please ask us for more details on how it works.",
    },
  ]
  export const blog = [
    {
      id: 1,
      
      title: "Embracing Remote Work: AI-Driven Productivity Solutions",
      desc: "Remote work is changing the landscape, and AI enhances efficiency. Tools for communication, task automation, and productivity analysis help teams collaborate effectively from anywhere. Embrace AI to create a dynamic remote work environment!",
      cover: "../images/bg1.jpg",
    },
    {
      id: 2,
      
      title: "Learn Artificial Intelligence Today",
      desc: "Understanding AI is key in today’s digital world. Learning AI enables data-driven decisions and innovation. Online courses make it easy to start, whether you're a leader or a tech enthusiast. Embrace the future—learn AI and stay competitive!",
      cover: "../images/blog/group2.jpg",
    },
    {
      id: 3,
      
      title: "Sustainable Practices in Business: The Role of AI",
      desc: "Sustainable practices are essential, and AI can optimize resource use and reduce waste. With predictive analytics and improved sourcing, AI supports eco-friendly strategies. Integrate AI into your sustainability efforts to attract conscious consumers!",
      cover: "../images/blog/group1.jpg",
    },
  ]
  export const testimonal = [
    {
      id: 1,
      name: "Jenny Batal",
      post: "Senior Manager",
      desc: "As a student, my time here has been transformative. The supportive environment encouraged me to delve into my interests and push my limits. I developed not only academically but also personally, forming lasting friendships and discovering my ultimate potential. I’m grateful for the opportunities that shaped my journey.",
      cover: "./images/jenny.jpeg",
    },
    {
      id: 2,
      name: "Jerome Porcado",
      post: "Full Stack Developer",
      desc: "Being a student in this program has been an incredible experience. The engaging curriculum and passionate teachers inspired me to delve deeper into my field. I’ve gained valuable skills and insights that have shaped my ultimate career path, and I feel more confident than ever about my future.",
      cover: "./images/jerome.jpg",
    },
    {
      id: 3,
      name: "John Kim Sagala",
      post: "Full Stack Developer",
      desc: "My journey as a student here has been eye-opening. The hands-on projects allowed me to delve into real-world challenges, fostering my creativity and critical thinking. I discovered my ultimate passion for innovation, and I’m excited to apply what I’ve learned in my future endeavors.",
      cover: "./images/HtozvSZy.jpeg",
    },
  ] 
  const user = [
    {
      id: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      gender: '',
      age: '',
      email: '',
      username: '',
      password: '',
      confirm_password: ''
    }

  ]