import React from 'react'

const CenterSpace = () => {
  return (
    <div className='space'>
      
    </div>
  )
}

export default CenterSpace
